<template>
  <el-row class="http404-container">
    <el-col v-bind="{ md: 12, xs: 24 }">
      <div class="pic-404">
        <img class="pic-404__parent" src="@/assets/401.svg" alt="404" />
      </div>
    </el-col>
    <el-col v-bind="{ md: 12, xs: 24 }">
      <div class="bullshit">
        <div class="bullshit__oops">{{ message }}</div>
        <!-- <div class="bullshit__info">
          All rights reserved
          <a
            style="color: #20a0ff"
            href="https://wallstreetcn.com"
            target="_blank"
            >wallstreetcn</a
          >
        </div> -->
        <div class="bullshit__headline">
          可能是您输入的网址不正确或者没有权限访问
        </div>
        <div class="bullshit__info">建议您检查输入的网址或者--</div>
        <a href="" class="bullshit__return-home">返回首页</a>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Page404',
  computed: {
    message() {
      return '抱歉！您访问的页面不存在...！'
    }
  }
}
</script>

<style lang="scss">
.http404-container {
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  .pic-404 {
    position: relative;
    width: 400px;
    margin: auto;
    // max-height: 400px;
    &__parent {
      width: 100%;
    }
  }
  .bullshit {
    position: relative;
    width: 80%;
    margin: 100px auto 0;
    &__oops {
      font-size: 28px;
      font-weight: bold;
      line-height: 40px;
      color: #1482f0;
      opacity: 0;
      margin-bottom: 30px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
    &__headline {
      font-size: 20px;
      line-height: 24px;
      color: #222;
      font-weight: bold;
      opacity: 0;
      margin-bottom: 40px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }
    &__info {
      font-size: 13px;
      line-height: 21px;
      color: grey;
      opacity: 0;
      margin-bottom: 30px;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.2s;
      animation-fill-mode: forwards;
    }
    &__return-home {
      display: block;
      float: left;
      width: 110px;
      height: 36px;
      background: #1482f0;
      border-radius: 100px;
      text-align: center;
      color: #ffffff;
      opacity: 0;
      font-size: 14px;
      line-height: 36px;
      cursor: pointer;
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
    }
    @keyframes slideUp {
      0% {
        transform: translateY(60px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
</style>
